import './styles.css';

export const Footer = () => {
    return (
        <div className="container-footer">
            <div className="content-footer">
                <p className="copy">@ site developed by Otávio Mendes · 2024</p>
            </div>
        </div>
    );
}